<script setup lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import MSelect from "@mozaic-ds/vue-3/src/components/select/MSelect.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";

import CardContainer from "@/commons/components/CardContainer.vue";
import LayoutSection from "@/commons/components/LayoutSection.vue";
import HelpLink from "@/commons/components/UserDocumentationLinks/HelpLink.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { syncKong } from "@/commons/services/gateway-service";

import contents from "@/admin/contents/gateway-synchronization";

const store = useStore();

const launchSync = async () => {
  await syncKong(selectedTenant.value);
  store.commit("postSuccessNotification", {
    title: contents.launchedSyncToastMessage,
  });
};

const batchDashboardUrl = computed((): string => {
  return store.getters["config/batchDashboardUrl"];
});

const tenantOptions = [
  { value: "eu", text: "Europe" },
  { value: "br", text: "Brazil" },
  { value: "za", text: "South Africa" },
];

const selectedTenant = ref("eu");
</script>

<template>
  <LayoutSection class="gateway-synchronization">
    <CardContainer class="gateway-synchronization__card">
      <div class="gateway-synchronization__card-content">
        <div class="gateway-synchronization__card-content-details">
          <h5>{{ contents.synchronizeWithKongTitle }}</h5>

          <Markdown :content="contents.synchronizeWithKongDescription" />

          <div>
            <HelpLink
              href="https://app.gitbook.com/o/0gRlAkSocTvnN36NTnDZ/s/y110QPmtzMZetBLdm8Kw/functional-doc/kong-synchronization"
              :label="contents.readDocumentationLink"
              openInNewTab
            />
          </div>
        </div>
        <div class="gateway-synchronization__launch-sync">
          <MSelect
            class="gateway-synchronization__tenant-select"
            id="tenant-select"
            v-model="selectedTenant"
            :options="tenantOptions"
            :placeholder="contents.selectTenant"
          />
          <MButton
            class="gateway-synchronization__launch-sync-button"
            :label="contents.synchronizeBtnLabel"
            @click="launchSync"
          />
        </div>
        <div>
          <HelpLink
            class="gateway-synchronization__launch-sync-link"
            :href="batchDashboardUrl"
            :label="contents.followBatchExecutionStatus"
            openInNewTab
          />
        </div>
      </div>
      <MNotification
        class="gateway-synchronization__warning-notification"
        type="warning"
      >
        {{ contents.warningMessage }}
      </MNotification>
    </CardContainer>
  </LayoutSection>
</template>

<style lang="scss">
.gateway-synchronization__card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.gateway-synchronization__card-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.gateway-synchronization__card-content-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.gateway-synchronization__launch-sync {
  display: flex;
  column-gap: 2rem;
}

.gateway-synchronization__launch-sync-button {
  width: 14rem;
}

.gateway-synchronization__warning-notification {
  width: 18rem;
  height: 5.5rem;
}

.gateway-synchronization__launch-sync-link {
  float: right;
}
.gateway-synchronization__tenant-select {
  width: 25rem;
}
</style>
