<template>
  <div class="contract-card-actions-menu">
    <div
      class="contract-card-actions-menu__header"
      :class="{ 'contract-card-actions-menu__header--oauth': isOAuthContract }"
    >
      <h6
        class="contract-card-actions-menu__header-title"
        :class="{
          'contract-card-actions-menu__header-title--oauth': isOAuthContract,
        }"
      >
        {{ contents.menuTitle }}
      </h6>
      <IconButton
        class="contract-card-actions-menu__close-button"
        :mozaicIconName="closeIconName"
        @click="$emit('clickOnClose')"
      />
    </div>
    <template v-if="isOAuthContract">
      <div class="contract-card-actions-menu__oauth-items">
        <div
          v-for="item of actionItems"
          :key="item.actionName"
          class="contract-card-actions-menu__oauth-item-container"
          @click="$emit(item.actionName)"
        >
          <div
            class="contract-card-actions-menu__oauth-item"
            :class="item.extraClass"
          >
            <MIcon :name="item.iconName" />
            <span :data-cy="`token-action-${item.actionName}`">{{
              item.label
            }}</span>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        v-for="item of actionItems"
        :key="item.actionName"
        class="contract-card-actions-menu__item"
        :class="item.extraClass"
        @click="$emit(item.actionName)"
      >
        <span :data-cy="`token-action-${item.actionName}`">{{
          item.label
        }}</span>
        <MBadge v-if="actionIsRecommended(item)" type="success">{{
          contents.recommended
        }}</MBadge>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import MBadge from "@mozaic-ds/vue-3/src/components/badge/MBadge.vue";
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import { PropType } from "vue";

import IconButton from "@/commons/components/IconButton.vue";

import { ContractCardActionItem } from "@/dashboard/utils/token-action-utils";

import { Contract } from "@/commons/domain/models/contract";

import contents from "@/dashboard/contents/token-action";

export default {
  components: { MBadge, MIcon, IconButton },
  props: {
    actionItems: {
      type: Array as PropType<ContractCardActionItem[]>,
      required: true,
    },
    contract: {
      type: Object as PropType<Contract>,
      required: true,
    },
    isOAuthContract: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "rotateToken",
    "extendToken",
    "deleteToken",
    "unsubscribe",
    "clickOnClose",
  ],
  data() {
    return {
      contents,
    };
  },
  computed: {
    closeIconName(): string {
      return this.isOAuthContract
        ? "ControlCircleCross24"
        : "ControlCircleCross32";
    },
  },
  methods: {
    actionIsRecommended(actionItem: ContractCardActionItem): boolean {
      return (
        actionItem.isRecommendedPredicate != undefined &&
        actionItem.isRecommendedPredicate(this.contract)
      );
    },
  },
};
</script>

<style lang="scss">
.contract-card-actions-menu {
  display: flex;
  flex-direction: column;

  background-color: var(--color-background-primary);
  border-radius: 0.5rem;
}

.contract-card-actions-menu__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;

  border-bottom: var(--border-default);
}

.contract-card-actions-menu__header-title {
  margin-left: 0.5rem;
}

.contract-card-action-item--critical {
  color: var(--color-status-danger);
}

.contract-card-actions-menu__item {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 0.625rem;

  cursor: pointer;

  border-bottom: var(--border-default);
  gap: 0.5rem;
}

.contract-card-actions-menu__item:hover {
  background-color: var(--color-background-secondary-light);
}

.contract-card-actions-menu__header--oauth {
  padding: 1.5rem 1.5rem 0.5rem;

  border-bottom: none;
}

.contract-card-actions-menu__header-title--oauth {
  margin: 0;
}

.contract-card-actions-menu__oauth-items {
  display: flex;
  flex-direction: column;

  padding: 0 1.5rem;
}

.contract-card-actions-menu__oauth-item-container {
  display: flex;
}

.contract-card-actions-menu__oauth-item {
  display: flex;
  align-items: center;

  padding: 0.4375rem 0.75rem;
  margin: 0 0.75rem;

  cursor: pointer;
  gap: 0.5rem;
}

.contract-card-actions-menu__oauth-item:hover {
  background-color: var(--color-background-secondary-light);
  border-radius: 0.25rem;
}
</style>
