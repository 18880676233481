import { isRecommendingTokenRotation } from "@/commons/libs/utils/contractUtils";

import { Contract } from "@/commons/domain/models/contract";

import contents from "@/dashboard/contents/token-action";

export interface ContractCardActionItem {
  actionName: string;
  allowedActionName: string;
  label: string;
  isRecommendedPredicate?: (contract: Contract) => boolean;
  extraClass?: string;
  exclusionPredicate?: (contract: Contract) => boolean;
  iconName?: string;
}

export const CONTRACT_CARD_ACTION_ITEMS: ContractCardActionItem[] = [
  {
    allowedActionName: "token.rotate",
    actionName: "rotateToken",
    label: contents.rotateApiKey,
    isRecommendedPredicate: (contract: Contract) =>
      isRecommendingTokenRotation(contract),
  },
  {
    allowedActionName: "token.extend",
    actionName: "extendToken",
    label: contents.extendApiKey,
  },
  {
    allowedActionName: "token.delete",
    actionName: "deleteToken",
    label: contents.deleteApiKey,
    extraClass: "contract-card-action-item--critical",
    exclusionPredicate: (contract: Contract) => {
      // We don't want to propose the action if the contract has only one token and we can unsubscribe because it is equivalent to unsubscribing
      return (
        contract.allowedActions["contract.delete"] &&
        Object.values(contract.tokens).length === 1
      );
    },
  },
  {
    allowedActionName: "contract.delete",
    actionName: "unsubscribe",
    label: contents.unsubscribe,
    extraClass: "contract-card-action-item--critical",
    iconName: "PublishTrashbin24",
  },
];

export function itemHasExclusionRestriction(
  item: ContractCardActionItem,
  contract: Contract,
) {
  return (
    item.exclusionPredicate != undefined && item.exclusionPredicate(contract)
  );
}
