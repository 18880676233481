export default {
  menuTitle: "Credentials actions",

  rotateApiKey: "Rotate this API Key (create new one)",
  extendApiKey: "Extend this API Key (update expiration date)",
  deleteApiKey: "Delete this API Key",
  unsubscribe: "Unsubscribe",

  recommended: "Recommended",
};
